import classnames from "classnames"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { withTranslation } from "../../../i18n/translate"
import ArrowDown from "../../../images/icons/arrow_down.svg"
import ArrowUp from "../../../images/icons/arrow_up.svg"
import EffectsLegendHoc from "../../effects-legend/effects-legend"
import ProductParam from "../product-param"
import {
  sortProductsByProductParameterGroup,
} from "../product-param-helpers"

import "./product-print-table.sass"

class ProductPrintTable extends React.Component {
  constructor(props) {
    super(props)

    const { productsDatabase, location } = props

    const searchParams = new URLSearchParams(location.search)

    const printProductParameterGroups = JSON.parse(decodeURIComponent(searchParams.get('cols'))) || [];

    this.onUpdateHandle = productsDatabase.onUpdate(() => {
      this.setState(this.calculateNewState())
    })

    this.state = {
      ...this.calculateNewState(),
      printIncludedColumns: this.props.productParamGroups.map(({strapiId}) => {
        return printProductParameterGroups.findIndex((id) => id === strapiId) !== -1
      }),
      leftButtonActive: false,
      rightButtonActive: true,
    }

  }

  componentDidMount() {
    document.documentElement.classList.add('_fp-is-product-print-table-page');
    setTimeout(() => {
      if (window.onprintready) {
        window.onprintready();
      }
    });
  }

  calculateNewState() {
    const filteredProducts =
      this.props.productsDatabase.getFilteredProducts() || []

    const ascending = this.state ? this.state.ascending : true
    const printIncludedColumns = this.state
      ? this.state.printIncludedColumns
      : []

    const sortBy = this.state ? this.state.sortBy : undefined

    return {
      ascending,
      filteredProducts: sortProductsByProductParameterGroup(
        sortBy,
        filteredProducts,
        this.props.t,
        ascending,
      ),
      listIsFiltered: this.props.productsDatabase.hasFilters(),
      printIncludedColumns,
      printModal: false,
      productContext: this.props.productsDatabase.getProductContext(),
    }
  }

  isSortable(group) {
    return false;
  }

  setSortGroup(group) {
    if (!this.isSortable(group)) {
      return
    }
    const filteredProducts =
      this.props.productsDatabase.getFilteredProducts() || []
    let ascending = this.state.ascending

    if (group === this.state.sortBy) {
      ascending = !ascending
    } else {
      ascending = true
    }

    this.setState({
      sortBy: group,
      ascending,
      filteredProducts: sortProductsByProductParameterGroup(
        group,
        filteredProducts,
        this.props.t,
        ascending,
      ),
    })
  }

  componentWillUnmount() {
    this.onUpdateHandle.unsubscribe()
  }

  render() {
    const { t } = this.props

    return (
      <>


        <div
          style={{
            display:
              this.state.filteredProducts.length === 0 ? "none" : "block",
          }}
          className="_fp-col-12 _fp-product-print-table-wrapper"
        >
          <EffectsLegendHoc products={this.state.filteredProducts} productParamGroups={this.props.productParamGroups} show={true}>
            <div className={classNames({"_fp-product-print-table": true})}>
              <table className={"_fp-product-print-table__table"}>
                <thead>
                <tr>
                  <th
                      className={"_fp-product-print-table__table__head"}
                  >
                    <div className="_fp-product-print-table__table__head__container _fp-product-print-table__table__head__container--sortable">
                      {this.props.t("PRODUCTS-PRODUCT_NAME")}
                      {undefined === this.state.sortBy && (
                          <span
                              className={
                                "_fp-print-hidden _fp-product-print-table__table__head__container__sort-icon"
                              }
                          >
                                {this.state.ascending ? (
                                    <ArrowDown />
                                ) : (
                                    <ArrowUp />
                                )}
                              </span>
                      )}
                    </div>
                  </th>
                  {this.props.productParamGroups
                      .filter((group, index) => this.state.printIncludedColumns[index])
                      .map((group, index) => (

                          <th
                              key={"group" + index}
                              className={classnames(
                                  "_fp-product-print-table__table__head",
                              )}
                          >
                            <div
                                className={classNames(
                                    "_fp-product-print-table__table__head__container",
                                    {
                                      "_fp-product-print-table__table__head__container--sortable":
                                          this.isSortable(group),
                                    },
                                )}
                            >
                              {group.type === "EFFECT"
                                  ? t("PRODUCTS-PRODUCT_FILTERS-EFFECT_COLON")
                                  : ""}
                              {group.name && t(group.name.key)}
                              {group === this.state.sortBy && (
                                  <span
                                      className={
                                        "_fp-print-hidden _fp-product-print-table__table__head__container__sort-icon"
                                      }
                                  >
                                  {this.state.ascending ? (
                                      <ArrowDown />
                                  ) : (
                                      <ArrowUp />
                                  )}
                                </span>
                              )}
                            </div>
                          </th>
                      ))}
                </tr>
                </thead>
                <tbody>
                {this.state.filteredProducts.map(
                    (product, productIndex) => (
                        <tr key={"productrow" + productIndex}>
                          <td>
                            <div className="_fp-product-print-table__cell _fp-product-print-table__cell--first">
                              {product.name}


                            </div>
                          </td>
                          {this.props.productParamGroups
                              .filter((group, index) => this.state.printIncludedColumns[index])
                              .map((group, index) => (
                                      <td
                                          key={"group_" + index}
                                      >
                                        <div className={"_fp-product-print-table__cell"}>
                                          <ProductParam
                                              param={product.getParamValues(group)}
                                              group={group}
                                              type={group.type}
                                          />
                                        </div>
                                      </td>
                                  ),
                              )}
                        </tr>
                    ),
                )}
                </tbody>
                <tfoot>
                <tr />
                </tfoot>
              </table>
            </div>
          </EffectsLegendHoc>
        </div>
      </>
    )
  }
}

ProductPrintTable.propTypes = {
  productsDatabase: PropTypes.object.isRequired,
  filterKey: PropTypes.string,
}

export default withTranslation()(ProductPrintTable)
