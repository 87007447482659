import { graphql } from "gatsby"
import React from "react"

import ProductView from "../components/product/product-view/product-view"
import { ProductsDatabase } from "../components/product/products-database"
import useProductParameterGroups from "../hooks/useProductParameterGroups"
import useProducts from "../hooks/useProducts"
import { useTranslation } from "../i18n/translate"
import Seo from "../components/seo";
import ProductPrintView from "../components/product/product-print-view/product-print-view";

const PrintProductsByBrand = ({ pageContext: { brand }, data, location, path }) => {
  const productParamGroups = useProductParameterGroups()
  const productParameterGroupsForFilter = productParamGroups.concat([
    {
      name: { key: "MARKETS-MARKET" },
      info: { key: "PRODUCTS-PRODUCT_FILTERS-MARKET-INFO" },
      strapiId: -2,
      prefix: null,
      suffix: null,
      type: "ENUM",
      order: 110,
    },
  ])

  const products = useProducts(true).filter((product) => {
    return product.hasBrand(brand)
  })

  const db = new ProductsDatabase(products, productParameterGroupsForFilter)

  const { t } = useTranslation()

  return (
      <>
        <Seo titleTranslatedString={t(data.strapiBrand?.name?.key)} indexPage={false} pathName={location.pathname}/>
        <ProductPrintView
            filterKey={"brand_" + data.strapiBrand.url}
            db={db}
            descriptionTranslatedString={t("PRODUCTS-META_DESCRIPTION")}
            titleTranslatedString={t(data.strapiBrand?.name?.key)}
            productParamGroups={productParamGroups}
            location={location}
            market={data.strapiBrand.id}
        />
      </>
  )
}

export default PrintProductsByBrand

export const query = graphql`
  query ($brand: String) {
    strapiBrand(url: { eq: $brand }) {
      id
      url
      name {
        key
      }
    }
  }
`
