import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import ProductPrintTable from "./product-print-table";

class ProductPrintView extends React.Component {
  constructor(props) {
    super(props)
    this.db = props.db
    this.filterKey = props.filterKey

    if (this.filterKey) {
      this.db.restoreState(this.props.getFilter(this.filterKey))
    }
  }

  render() {
    return (
        <ProductPrintTable
            onMount={(cb) => this.setState({ print: cb.print })}
            filterKey={this.filterKey}
            productsDatabase={this.db}
            productParamGroups={this.props.productParamGroups}
            location={this.props.location}
        />
    )
  }
}

ProductPrintView.propTypes = {
  titleTranslatedString: PropTypes.string,
  descriptionTranslatedString: PropTypes.string,
  productParamGroups: PropTypes.any,
  filterKey: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    getFilter: (key) => {
      return state.filters[key] || []
    },
  }
}

export default connect(
  mapStateToProps,
  undefined,
)(ProductPrintView)
